<template>
  <a-result
    status="403"
    title="403"
    sub-title="您暂无权限访问此页面，请联系系统管理员！"
  >
    <template #extra>
      <a-button type="primary" @click="back"> 返回上一层 </a-button>
    </template>
  </a-result>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>